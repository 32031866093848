import { AxiosError } from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router";
import { Alert } from "../../../../common/Alerts/Alerts";
import { Loader } from "../../../../common/Loader/Loader";
import { editCustomer, getCustomerInfoDetails } from "../customer-api";
import { CustomerDetails } from "./CustomerInfoForm/CustomerDetails";
import { CustomerInfoForm } from "./CustomerInfoForm/CustomerInfoForm";

export const CustomerInfo: React.FC = () => {
  const { customerId } = useParams();

  const { t } = useTranslation();

  const query = useQuery<CustomerDetails, AxiosError>("customer-details", () =>
    getCustomerInfoDetails(customerId!)
  );

  const queryClient = useQueryClient();

  const editCustomerMutation = useMutation(editCustomer, {
    onSuccess: () => queryClient.invalidateQueries("customer-details"),
  });

  const handleEdit = (edited: CustomerDetails) =>
    editCustomerMutation.mutateAsync({
      customerId: customerId!,
      editedCustomer: edited,
    });

  if (customerId === undefined) {
    return <Alert message={t("error.missing-customer-id")} />;
  }

  switch (query.status) {
    case "success":
      return (
        <div
          style={{
            marginLeft: "5vw",
            marginTop: "2vh",
          }}
        >
          <CustomerInfoForm details={query.data!} onEdit={handleEdit} />
        </div>
      );
    case "loading":
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            height: "90vh",
          }}
        >
          <Loader />
        </div>
      );
    case "error":
      return <div>Error: {query.error}</div>;
    default:
      return null;
  }
};
