"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCleanUrl = void 0;
/**
 * Removes query parameters and hashes from a given URL.
 */
function getCleanUrl(url, isAbsolute) {
    if (isAbsolute === void 0) { isAbsolute = true; }
    return [isAbsolute && url.origin, url.pathname].filter(Boolean).join('');
}
exports.getCleanUrl = getCleanUrl;
