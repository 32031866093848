export const ListIcon = ({ width = 61, height = 64, fill = "black" }) => {
  return (
    <svg width={width} height={height}>
      <path
        d="M51.8322 50.8886L58.7793 47.415L39.8815 40.4529L46.8436 59.3507L50.3172 52.4036L57.5997 59.6861C57.8086 59.895 58.0829 60 58.3572 60C58.6315 60 58.9058 59.895 59.1147 59.6861C59.5336 59.2671 59.5336 58.59 59.1147 58.1711L51.8322 50.8886ZM43.4975 44.0689L53.39 47.7139L49.2243 49.7957L47.1425 53.9614L43.4975 44.0689Z"
        fill={fill}
      />
      <path
        d="M30.5 15H49.7857C50.3782 15 50.8572 14.5211 50.8572 13.9286C50.8572 13.3361 50.3782 12.8571 49.7857 12.8571H30.5C29.9075 12.8571 29.4286 13.3361 29.4286 13.9286C29.4286 14.5211 29.9075 15 30.5 15Z"
        fill={fill}
      />
      <path
        d="M30.5 30H49.7857C50.3782 30 50.8572 29.5211 50.8572 28.9286C50.8572 28.3361 50.3782 27.8571 49.7857 27.8571H30.5C29.9075 27.8571 29.4286 28.3361 29.4286 28.9286C29.4286 29.5211 29.9075 30 30.5 30Z"
        fill={fill}
      />
      <path
        d="M23.6203 7.75821C23.1736 7.37464 22.4943 7.42285 22.1107 7.87392L15.4164 15.6825L11.3214 12.6118C10.85 12.2571 10.1782 12.3514 9.82248 12.8261C9.46677 13.2986 9.56319 13.9704 10.0368 14.325L14.9343 17.9989C15.1261 18.1436 15.3521 18.2132 15.5761 18.2132C15.8793 18.2132 16.1793 18.0857 16.3903 17.8382L23.7371 9.26678C24.1228 8.81892 24.0703 8.14392 23.6203 7.75821Z"
        fill={fill}
      />
      <path
        d="M23.6203 22.7582C23.1736 22.3736 22.4943 22.4239 22.1107 22.8739L15.4164 30.6825L11.3214 27.6118C10.85 27.2572 10.1782 27.3514 9.82248 27.8261C9.46677 28.2986 9.56319 28.9704 10.0368 29.325L14.9343 32.9989C15.1261 33.1436 15.3521 33.2132 15.5761 33.2132C15.8793 33.2132 16.1793 33.0857 16.3903 32.8382L23.7371 24.2668C24.1228 23.8189 24.0703 23.1439 23.6203 22.7582Z"
        fill={fill}
      />
      <path
        d="M22.1107 38.9464L15.4164 46.7539L11.3214 43.6832C10.85 43.3297 10.1782 43.4229 9.82248 43.8975C9.46677 44.37 9.56319 45.0418 10.0368 45.3964L14.9343 49.0704C15.1261 49.215 15.3521 49.2847 15.5761 49.2847C15.8793 49.2847 16.1793 49.1572 16.3903 48.9097L23.7371 40.3393C24.1228 39.8904 24.0714 39.2143 23.6214 38.8297C23.1736 38.4461 22.4943 38.4954 22.1107 38.9464Z"
        fill={fill}
      />
      <path
        d="M46.3828 0H14.6171C7.42355 0 1.57141 5.85214 1.57141 13.0457V44.8114C1.57141 52.005 7.42355 57.8571 14.6171 57.8571H35.8571C36.4496 57.8571 36.9286 57.3782 36.9286 56.7857C36.9286 56.1932 36.4496 55.7143 35.8571 55.7143H14.6171C8.60427 55.7143 3.71427 50.8243 3.71427 44.8114V13.0457C3.71427 7.03286 8.60427 2.14286 14.6171 2.14286H46.3828C52.3957 2.14286 57.2857 7.03286 57.2857 13.0457V39.6429C57.2857 40.2354 57.7646 40.7143 58.3571 40.7143C58.9496 40.7143 59.4285 40.2354 59.4285 39.6429V13.0457C59.4285 5.85214 53.5764 0 46.3828 0Z"
        fill={fill}
      />
    </svg>
  );
};
