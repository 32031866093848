import { getToken, setTokenValue } from '../App/auth';

import axios from 'axios';
import { getEnvironmentVariable } from './utils';

export interface AppConfig {
  baseUrl: string;
  appLanguage: string;
  clientId: string;
  modhiBaseUrl: string;
  clientSecret: string;
  modhiApiKey: string;
  mobileAppUrl:string;
}

export const reactAppConfig: AppConfig = {
  baseUrl: getEnvironmentVariable('REACT_APP_BASE_URL'),
  appLanguage: getEnvironmentVariable('REACT_APP_LANGUAGE'),
  clientId: getEnvironmentVariable('REACT_APP_CLIENT_ID'),
  clientSecret: getEnvironmentVariable('REACT_APP_CLIENT_SECRET'),
  modhiBaseUrl: getEnvironmentVariable('REACT_APP_MODHI_BASE_URL'),
  modhiApiKey: getEnvironmentVariable('REACT_APP_MODHI_X_API_KEY'),
  mobileAppUrl:getEnvironmentVariable('REACT_APP_MOBILE_URL'),
};

export const modhiAxios = axios.create({
  baseURL: reactAppConfig.modhiBaseUrl,
});
export const backend = axios.create({
  baseURL: reactAppConfig.baseUrl,
});


modhiAxios.interceptors.request.use(async (config) => {
  if (config.url === '/login') {
    return config;
  }
  const token = await getToken();
  if (token) {
    config!.headers!['x-api-key'] = reactAppConfig.modhiApiKey;
    // config!.headers!.Authorization = token.authToken;
  }
  return config;
});

backend.interceptors.request.use(async (config) => {
  if (config.url === '/login') {
    return config;
  }

  const token = await getToken();
  if (token) {
    config!.headers!.Authorization = token.authToken;
  }

  return config;
});

backend.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.toJSON().status === 401) {
      setTokenValue(undefined);
      window.location.replace('/login');
    }
    return Promise.reject(error);
  }
);
modhiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.toJSON().status === 401) {
      setTokenValue(undefined);
      window.location.replace('/login');
    }
    return Promise.reject(error);
  }
);

