import { setLocale } from "yup";

setLocale({
  mixed: {
    required: "error.required-field",
  },
  string: {
    email: "error.invalid-email",
  },
});
