import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { BrowserRouter } from "react-router-dom";

export const queryClient = new QueryClient();
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    secondary: true;
  }
}

export const AppProviders: React.FC = ({ children }) => {
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            // fontSize: "1rem",
            color: "#61D8AA",
            textTransform: "capitalize",
            fontFamily: "reader",
            fontSize: "1rem",
          },
        },
        variants: [
          {
            props: { variant: "secondary" },
            style: {
              borderRadius: "10px",
              backgroundColor: "#61D8AA",
              color: "#fff",
              "&:hover": {
                // backgroundColor: "#61D8AA",
                // color: "#fff",
              },
            },
          },
          {
            props: { variant: "text" },
            style: {},
          },
        ],
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>{children}</BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
