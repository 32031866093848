import { TFunctionKeys } from "i18next";
import { i18n } from "../utils/i18n/i18n-config";

export enum AppError {}
// ACCESS_DENIED = 'ACCESS_DENIED',

const errorMessageMap = new Map<AppError, TFunctionKeys>([
  // [AppError.ACCESS_DENIED, 'error.you-do-not-have-permissions-to-access-this'],
]);

export const getErrorMessage = (errorCode: AppError): string => {
  const errorMessage = errorMessageMap.get(errorCode);
  return errorMessage ? i18n.t(errorMessage) : "";
};
