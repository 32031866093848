import { GridColDef } from "@mui/x-data-grid";
import i18n from "i18next";

export default class GridFactory {
  constructor(private readonly translationPath: string) {}

  constructColumn = (
    field: string,
    type: string = "string",
    flex: number = 1,
    editable: boolean = true
  ): GridColDef => {
    return {
      field,
      type,
      headerName: i18n.t(`${this.translationPath}.${field}`),
      flex,
      editable,
    };
  };
}
