import { rest } from "msw";
import { res } from "../../mocks/res";
import { OAuthToken, TOKEN_LIFE, REFRESH_TOKEN_LIFE } from "../../App/auth";

const mockToken: OAuthToken = {
  access_token: "12345",
  refresh_token: "56789",
  expires_in: TOKEN_LIFE,
  refresh_token_expires_in: REFRESH_TOKEN_LIFE,
};

export const handlers = [
  rest.post("/oauth/token", (req, _, ctx) => {
    return res(ctx.json(mockToken));
  }),
  rest.post("/revoke-token", (req, _, ctx) => {
    return res();
  }),
];
