import { CSSProperties } from "react";
import MoonLoader from "react-spinners/MoonLoader";

export interface LoaderProps {
  size?: "large" | "small" | "button";
}

export const Loader: React.FC<LoaderProps> = ({ size = "large" }) => {
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  let loaderSize;
  switch (size) {
    case "large":
      loaderSize = 150;
      break;

    case "small":
      loaderSize = 100;
      break;

    case "button":
      loaderSize = 30;
      break;

    default:
      break;
  }
  return (
    <MoonLoader
      color={"#36d7b7"}
      loading={true}
      cssOverride={override}
      size={loaderSize}
    />
  );
};
