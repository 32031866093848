import * as Yup from "yup";

import { Credentials } from "../../App/auth";
import { Form } from "../../common/Forms/Form";
import { Input } from "../../common/Forms/Input";
import { useTranslation } from "react-i18next";

export interface LoginFormProps {
  onSubmit: (values: Credentials) => void;
}

export const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required().email("Invalid email"),
    password: Yup.string().required(),
  });
  //sparikh@heaptrace.com
  //123456
  const initialValues: Credentials = {
    email: "",
    password: "",
  };
  return (
    <Form
      onSubmit={onSubmit}
      buttonText={t("login.sign-in")}
      initialValues={initialValues}
      validationSchema={LoginSchema}
    >
      <Input name="email" label={t("login.email")} type="email" />
      <Input name="password" label={t("login.password")} type="password" />
      <div className="flex items-center">
        <div className="ml-auto text-sm">
          <button className="font-medium text-brand-600 hover:text-brand-500">
            {t("login.forgot-your-password")}
          </button>
        </div>
      </div>
    </Form>
  );
};
