import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./locales/en/translationEn.json";
import translationFi from "./locales/no/translationNo.json";
import { reactAppConfig } from "../../config/react-app-config";

export enum Language {
  EN = "en",
  FI = "fi",
}

export const resources = {
  en: {
    translation: translationEn,
  },
  fi: {
    translation: translationFi,
  },
} as const;

i18n.use(initReactI18next).init({
  resources,
  ns: ["translation"],
  lng: reactAppConfig.appLanguage,
  fallbackLng: Language.EN,
  debug: false,
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
  },
});

export { i18n };
