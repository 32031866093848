import "./ModhiScriptPage.css";

import { GridCheckIcon, GridCloseIcon } from "@mui/x-data-grid";

export const MODHIGRIDCOLOUMS = [
  {
    field: "id",
    type: "string",
    headerName: "ID",
    headerClassName: "super-header",
    minWidth: 70,
    flex: 1,
  },
  {
    field: "InstallationNumber",
    type: "string",
    headerName: "InstallationNumber",
    headerClassName: "super-header",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "MeterNumber",
    type: "string",
    headerName: "MeterNumber",
    headerClassName: "super-header",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "ExternalCustomerNumber",
    type: "string",
    headerName: "ExternalCustomerNumber",
    headerClassName: "super-header",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "CustomerFirstName",
    type: "string",
    headerName: "CustomerFirstName",
    headerClassName: "super-header",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "CustomerLastName",
    type: "string",
    headerName: "CustomerLastName",
    headerClassName: "super-header",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "Address",
    type: "string",
    headerName: "Address",
    headerClassName: "super-header",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "Phone number",
    type: "string",
    headerName: "Phone number",
    headerClassName: "super-header",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "Email",
    type: "string",
    headerName: "Email",
    headerClassName: "super-header",
    minWidth: 300,
    flex: 1,
  },
  {
    field: "Postal code",
    type: "string",
    headerName: "Postal code",
    headerClassName: "super-header",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "City",
    type: "string",
    headerName: "City",
    headerClassName: "super-header",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "Has active meter",
    type: "boolean",
    headerName: "Has Active meter",
    headerClassName: "super-header",
    minWidth: 150,
    renderCell: (params: any) => {
      return params.value ? (
        <GridCheckIcon
          style={{
            color: "white",
          }}
        />
      ) : (
        <GridCloseIcon
          style={{
            color: "white",
          }}
        />
      );
    },
  },
  {
    field: "SSN",
    type: "string",
    headerName: "SSN",
    headerClassName: "lastcolumnSeparator",
    minWidth: 150,
    flex: 1,
  },
];

export const MODHIGRIDHEADER = [
  { label: "ID", key: "id" },
  { label: "InstallationNumber", key: "InstallationNumber" },
  { label: "MeterNumber", key: "MeterNumber" },
  { label: "ExternalCustomerNumber", key: "ExternalCustomerNumber" },
  { label: "CustomerFirstName", key: "CustomerFirstName" },
  { label: "CustomerLastName", key: "CustomerLastName" },
  { label: "Address", key: "Address" },
  { label: "Phone number", key: "Phone number" },
  { label: "Email", key: "Email" },
  { label: "Postal code", key: "Postal code" },
  { label: "City", key: "City" },
  { label: "Has Active meter", key: "Has active meter" },
  { label: "SSN", key: "SSN" },
];
