import { Delete, Edit, MailOutline, Preview } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import { Box } from "@mui/system";

const CustomerActions = (props: any) => {
  return (
    <Box>
      <Tooltip title="Send Mail">
        <IconButton onClick={() => {}}>
          <MailOutline sx={{ color: "#FC0" }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="View Submission">
        <IconButton onClick={() => {}}>
          <Preview sx={{ color: "#33FF9F" }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit Submission">
        <IconButton onClick={() => {}}>
          <Edit sx={{ color: "#33A8FF" }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Submission">
        <IconButton onClick={() => {}}>
          <Delete sx={{ color: "#FF7433" }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default CustomerActions;
