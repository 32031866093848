// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navbar_textColor__9dQQB\n{\n    color: black;\n}\n.Navbar_homeBtnAlign__op9Dm{\n    display: flex;\n    justify-content: center;\n    align-content: center;\n    align-items: center;    \n}", "",{"version":3,"sources":["webpack://./src/pages/NavBar/Navbar.module.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;AAChB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,mBAAmB;AACvB","sourcesContent":[".textColor\n{\n    color: black;\n}\n.homeBtnAlign{\n    display: flex;\n    justify-content: center;\n    align-content: center;\n    align-items: center;    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textColor": "Navbar_textColor__9dQQB",
	"homeBtnAlign": "Navbar_homeBtnAlign__op9Dm"
};
export default ___CSS_LOADER_EXPORT___;
