import { modhiAxios } from "../../config/react-app-config";

export interface StopList {
  Address: string;
  City: string;
  CustomerFirstName: string;
  CustomerLastName: string;
  Email: string;
  ExternalCustomerNumber: string;
  InstallationNumber: string;
  MeterNumber: string;
  "Phone number": string;
  "Postal code": string;
  "Has active meter":boolean;
  SSN:string;
}

export const getStopList = (): Promise<StopList[]> => {
  return modhiAxios.get("/modhi/stop-list").then((response) => response.data);
};
