import "react-phone-number-input/style.css";
import "./Modal.css";

import { Backdrop, Box, Card, CircularProgress } from "@mui/material";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

import { AxiosError } from "axios";
import { isExistingCustomerFetch } from "./impersonation-api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

let initialState: any;
const Modal = ({ setOpenModal }: any) => {
  const [value, setValue] = useState("");
  const [valid, setValid] = useState(initialState);
  const [btnDisabled, setBtnDisabled] = useState(true);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const functionCalls = async () => {
    try {
      if (!value) {
        setValid("Please Enter Impersonate Customer Phone Number!!");
        return;
      }
      setLoading(true);
      const response = await isExistingCustomerFetch(value);

      if (response.data.exists) {
        setOpenModal(false);

        impersonationTag();
      } else {
      }
      setLoading(false);
    } catch (error: any | Error | AxiosError) {
      setLoading(false);
      if (error) {
        const { response } = error;
        if (response) {
          // const [errorMsg] = response.data.errorCodes;

          setValid("Impersonate Customer Not Found");
        } else {
          setValid(`Something goes wrong`);
        }
      }
    }
  };

  const impersonationTag = () => {
    if (value !== null) {
      navigate("/impersonation", { state: { value } });
    }
  };
  const handlePhoneNumber = (e: any) => {
    e &&
    isPossiblePhoneNumber(e) &&
    isValidPhoneNumber(e) &&
    formatPhoneNumber(e) &&
    formatPhoneNumberIntl(e)
      ? setPhoneNumber(e)
      : arrowfun();
  };
  const arrowfun = () => {
    setValid("Enter valid phone number");
    setBtnDisabled(true);
  };
  const setPhoneNumber = (e: any) => {
    setValue(e);
    setValid(initialState);
    setBtnDisabled(false);
  };
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 1100 }} open={true}>
      <Card
        variant="outlined"
        sx={{
          borderRadius: "20px",
          boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
        }}
        className="p-6"
      >
        {" "}
        <div className="title">
          <h1 className="capitalize">Enter Impersonation target Number</h1>
        </div>
        <div
          style={{
            marginTop: "15%",
            width: "100%",
          }}
        >
          {loading && (
            <Box className="flex place-content-center">
              <CircularProgress />
            </Box>
          )}
          <PhoneInput
            placeholder="Enter phone number"
            value={value}
            defaultCountry="NO"
            onChange={(e) => handlePhoneNumber(e)}
            error={
              value
                ? isValidPhoneNumber(value)
                  ? undefined
                  : "Invalid phone number"
                : "Phone number required"
            }
            containerstyle={{
              border: "10px solid black",
            }}
            inputstyle={{
              background: "lightblue",
            }}
            required
          />
          <div
            style={{
              marginLeft: "15%",

              color: "red",
            }}
            className="pt-2"
          >
            {valid}
          </div>
        </div>
        <div className="flex footer justify-between">
          <button
            id="cancelBtn"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Cancel
          </button>
          <button onClick={functionCalls} disabled={btnDisabled || loading}>
            Continue
          </button>
        </div>
      </Card>
    </Backdrop>
  );
};

export default Modal;
