import { CustomerOverviewPage } from "../pages/CustomerOverviewPage/CustomerOverviewPage";
import { CustomerPage } from "../pages/CustomerOverviewPage/CustomerPage/CustomerPage";
import { DashboardScreen } from "../pages/Dashboard/DashboardScreen";
import ImpersonationPage from "../pages/Impersonation/ImpersonationPage";
import { LoginPage } from "../pages/LoginPage/LoginPage";
import { ModhiScriptPage } from "../pages/ModhiOverviewPage/ModhiScriptPage";
import { RequireAuth } from "./RequireAuth";
import { Role } from "../pages/LoginPage/user-api";
import { RouteObject } from "react-router-dom";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <RequireAuth roleRequired={Role.ADMIN}>
        {/* <CustomerOverviewPage /> */}
        <DashboardScreen />
      </RequireAuth>
    ),
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/customeroverview",
    element: (
      <RequireAuth roleRequired={Role.ADMIN}>
        <CustomerOverviewPage />
      </RequireAuth>
    ),
  },
  {
    path: "/customer/:customerId",
    element: (
      <RequireAuth roleRequired={Role.ADMIN}>
        <CustomerPage />
      </RequireAuth>
    ),
  },
  {
    path: "/modhiScriptList",
    element: (
      <RequireAuth roleRequired={Role.ADMIN}>
        <ModhiScriptPage modhiCustomer={[]} />
      </RequireAuth>
    ),
  },
  {
    path: "/impersonation",
    element: (
      <RequireAuth roleRequired={Role.ADMIN}>
        <ImpersonationPage />
      </RequireAuth>
    ),
  },
];

export const routePath = {
  customerEditor: (customerId: number) => `/customer/${customerId}`,
};
