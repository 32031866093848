import axios from "axios";

export interface CustomerMeterAddress {
  id?: string;
  street?: string;
  postalCode?: string;
  city?: string;
}

export interface CustomerMeter {
  id?: string;
  name?: string;
  meterId?: string;
  meterNumber?: string;
  status?: string;
  address?: CustomerMeterAddress;
  activeFrom?: string;
  activeUntil?: string;
  gridArea?: string;
}

export interface CustomerMeterResponse {
  meters: CustomerMeter[];
}

export const getCustomerMeters = (
  customerId: string
): Promise<CustomerMeterResponse> => {
  return axios
    .get(`/admin/customer/${customerId}/electricity/meters`)
    .then((res) => res.data);
};

interface CustomerMeterCreateResponse {
  id: string;
}

export const createCustomerMeter = (
  customerId: string,
  data: CustomerMeter
): Promise<CustomerMeterCreateResponse> => {
  return axios
    .post(`/admin/customer/${customerId}/electricity/meters`, data)
    .then((res) => res.data);
};

export const updateCustomerMeter = (
  customerId: string,
  meterId: string,
  data: CustomerMeter
): Promise<void> => {
  return axios
    .put(`/admin/customer/${customerId}/electricity/meters/${meterId}`, data)
    .then((res) => res.data);
};
