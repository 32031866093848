import { Credentials, useLogin } from "../../App/auth";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { LoginForm } from "./LoginForm";
import { useTranslation } from "react-i18next";
export const LoginPage = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { t } = useTranslation();
  const { requestLogin, error } = useLogin();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    if (
      error &&
      error.response &&
      error.response.status &&
      [400, 401, 402].includes(error.response.status)
    ) {
      setErrorMessage(t("error.invalid-email-or-password"));
    } else {
      setErrorMessage(undefined);
    }
  }, [error, t]);

  const onLogin = async (data: Credentials) => {
    const token = await requestLogin(data);
    setOpen(true);
    let from = (location.state as any)?.from?.pathname || "/";

    if (token) {
      // window.location.replace(from);
      // return <Navigate to="/" replace={true} />;
      navigation("/", { replace: true });
    }
    return token;
  };

  return (
    <div className="h-screen bg-gray-50">
      <div className="min-h-3/4 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {t("login.sign-in-to-your-account")}
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md relative">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <LoginForm onSubmit={onLogin} />
            {errorMessage && (
              <div
                className="text-red-500 text-s text-right italic mt-6"
                role="alert"
                data-testid="login-error"
              >
                <p>{t(errorMessage)}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
