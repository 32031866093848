import { Role, User } from "./user-api";

import { res } from "../../mocks/res";
import { rest } from "msw";

export const mockUser: User = {
  id: 1,
  email: "demoUser@demo.com",
  roles: [Role.ADMIN],
};

export const handlers = [
  rest.get("/user", (req, _, ctx) => {
    return res(ctx.json(mockUser));
  }),
];
