import "./ModhiScriptPage.css";

import { MODHIGRIDCOLOUMS, MODHIGRIDHEADER } from "./Constants";
import { StopList, getStopList } from "./modhiScript-api";

import { AxiosError } from "axios";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import { DataGrid } from "@mui/x-data-grid";
import { Loader } from "../../common/Loader/Loader";
import { useQuery } from "react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface ModhiListProps {
  readonly modhiCustomer: StopList[];
}
export const ModhiScriptPage: React.FC<ModhiListProps> = (props) => {
  const [pageSize, setPageSize] = useState(50);
  const [query, setQuery] = useState("");

  const getBackgroundColor = (color: string, mode: string) =>
    mode === "dark" ? color : color;

  const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === "dark" ? color : color;
  const styles = {
    rowStyling: {
      color: "white",
      fontFamily: "reader",
      "& .super-app-theme--Open": {
        bgcolor: (theme: any) =>
          getBackgroundColor("#1e2828", theme.palette.mode),
        "&:hover": {
          bgcolor: (theme: any) =>
            getHoverBackgroundColor("#343d3d", theme.palette.mode),
        },
      },
    },
    headerStyling: {
      "& .super-header": {
        backgroundColor: "#1E2828",
        color: "white",
        fontFamily: "reader",
        flex: 1,
      },
      "& .lastcolumnSeparator": {
        backgroundColor: "#1E2828",
        color: "white",
        fontFamily: "reader",
        flex: 1,
      },
      "& .MuiDataGrid-sortIcon": {
        color: "white",
      },
    },
  };

  const { isLoading, data, error, status } = useQuery<StopList[], AxiosError>(
    "stop-list",
    { queryFn: () => getStopList(), keepPreviousData: true }
  );
  const fetchedList: StopList[] = data || [];
  switch (status) {
    case "success":
      return (
        <div className="px-6">
          <div className="flex mt-20 justify-between">
            <div>
              <CSVLink
                data={fetchedList.map((cb, index) => ({
                  ...cb,
                  id: index + 1,
                }))}
                headers={MODHIGRIDHEADER}
                filename="modhiList.csv"
              >
                <Button variant="secondary">
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>Export CSV</span>
                </Button>
              </CSVLink>
            </div>
            <div>
              <input
                type="text"
                placeholder="search..."
                style={{
                  borderRadius: "10px",
                  borderColor: "black",
                  cursor: "pointer",
                }}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
          <div
            className="grid"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="font-bold pb-2"
              style={{
                textAlign: "center",
                fontSize: "1.25rem",
              }}
            >
              <Title />
            </div>
            <Box className="pb-2" sx={{ height: "76vh", width: "100%" }}>
              <DataGrid
                disableColumnMenu
                rowHeight={50}
                columns={MODHIGRIDCOLOUMS}
                rows={fetchedList
                  .filter(
                    (user) =>
                      (user.InstallationNumber || "").includes(query) ||
                      (user.MeterNumber || "").includes(query) ||
                      (user.SSN || "").includes(query) ||
                      (user.ExternalCustomerNumber || "").includes(query) ||
                      (user.CustomerFirstName || "")
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      (user.CustomerLastName || "")
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      (user.Address || "")
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      (user["Phone number"] || "").includes(query) ||
                      (user.Email || "")
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      (user["Postal code"] || "")
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      (user.City || "")
                        .toLowerCase()
                        .includes(query.toLowerCase())
                  )
                  .map((cb, index) => ({
                    ...cb,
                    id: index + 1,
                  }))}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                    },
                  },
                }}
                disableColumnSelector={true}
                disableColumnFilter={true}
                rowsPerPageOptions={[10, 20, 50, 100]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                sortingOrder={["asc", "desc"]}
                getRowClassName={(params) => `super-app-theme--Open`}
                sx={[styles.headerStyling, styles.rowStyling]}
                disableSelectionOnClick
              />
            </Box>
          </div>
        </div>
      );
    case "loading":
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            height: "90vh",
          }}
        >
          <Loader />
        </div>
      );
    case "error":
      return <div>Error: {error}</div>;
    default:
      return null;
  }
};

const Title = () => {
  const { t } = useTranslation();

  return <h2>{t("modhiOverview.title")}</h2>;
};
