import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { Loader } from "../../../../common/Loader/Loader";
import {
  createCustomerMeter,
  CustomerMeter,
  CustomerMeterResponse,
  getCustomerMeters,
  updateCustomerMeter,
} from "./customer-meters-api";
import CustomerMetersList, {
  CustomerMeterListItem,
} from "./CustomerMetersList/CustomerMetersList";

enum MeterStatus {
  Pending = "PENDING",
}

const mapMetersResponseToGridItems = (
  meters?: CustomerMeter[]
): CustomerMeterListItem[] => {
  if (!meters || meters.length === 0) return [];

  return meters.map((x, i) => {
    return {
      ...x,
      ...x.address,
      rowId: i,
      id: x.id,
      addressId: x.address?.id,
    };
  });
};

const mapGridItemToMeter = (gridItem: CustomerMeterListItem): CustomerMeter => {
  return {
    ...gridItem,
    address: {
      id: gridItem.addressId,
      street: gridItem.street,
      city: gridItem.city,
      postalCode: gridItem.postalCode,
    },
  };
};

const CustomerMeters: React.FC = () => {
  const { customerId } = useParams();
  const [meters, setMeters] = useState<CustomerMeterListItem[]>([]);
  const query = useQuery<CustomerMeterResponse, AxiosError>(
    "customerMeters",
    () => getCustomerMeters(customerId!)
  );

  const createMutation = useMutation((meter: CustomerMeter) =>
    createCustomerMeter(customerId!, meter)
  );
  const updateMutation = useMutation((meter: CustomerMeter) =>
    updateCustomerMeter(customerId!, meter.id!, meter)
  );

  useEffect(() => {
    const meterItems = mapMetersResponseToGridItems(query.data?.meters);
    setMeters(meterItems);
  }, [query.data]);

  const onAdd = (): void => {
    setMeters(addMeter(meters));
  };

  const onEdit = async (meter: CustomerMeterListItem): Promise<void> => {
    const meterMap: CustomerMeter = mapGridItemToMeter(meter);

    if (!meter.id) {
      const createResult = await createMutation.mutateAsync(meterMap);
      meter.id = createResult.id;
    } else {
      updateMutation.mutate(meterMap);
    }
  };
  switch (query.status) {
    case "success":
      return (
        <div>
          <CustomerMetersList meters={meters} onAdd={onAdd} onEdit={onEdit} />
        </div>
      );
    case "loading":
      return (
        <div style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          height: "90vh"
        }}>
          <Loader />
        </div>
      );
    case "error":
      return <div>Error: {query.error}</div>;
    default:
      return null;
  }
};

export default CustomerMeters;

export const addMeter = (
  meters: CustomerMeterListItem[]
): CustomerMeterListItem[] => {
  return [
    ...meters,
    initializeGridItem((meters[meters.length - 1]?.rowId || 0) + 1),
  ];
};

const initializeGridItem = (rowId: number): CustomerMeterListItem => {
  return {
    rowId: rowId,
    name: "",
    meterNumber: "",
    meterId: "",
    status: MeterStatus.Pending,
    city: "",
    street: "",
    postalCode: "",
    activeFrom: "",
    activeUntil: "",
    gridArea: "",
  };
};
