import { response, context, ResponseTransformer } from "msw";

export function res(...transformers: ResponseTransformer<any>[]) {
  return response(
    ...transformers,
    context.status(200),
    context.set("Content-Type", "application/json"),
    context.set("Access-Control-Allow-Origin", "*")
  );
}
