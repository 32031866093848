import { CustomerBasics, CustomerOverview } from "./CustomerBasics";

import { AxiosError } from "axios";
import { CustomerOverviewList } from "./CustomerOverviewList/CustomerOverviewList";
import { Loader } from "../../common/Loader/Loader";
import { getAllCustomers } from "./customers-api";
import { routePath } from "../../App/routes";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";

export const CustomerOverviewPage = () => {
  const query = useQuery<CustomerOverview, AxiosError>("customers", () =>
    getAllCustomers()
  );
  const customers: CustomerBasics[] | undefined = query.data?.customers;

  const navigate = useNavigate();

  switch (query.status) {
    case "success":
      return (
        <div data-testid="customer-overview">
          <CustomerOverviewList
            customers={customers || []}
            onEdit={(customerId) => {
              navigate(routePath.customerEditor(customerId));
            }}
          />
        </div>
      );
    case "loading":
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            height: "90vh",
          }}
        >
          <Loader />
        </div>
      );
    case "error":
      return <div>Error: {query.error}</div>;
    default:
      return null;
  }
};
