import Nav from "../pages/NavBar/Navbar";
import { routes } from "./routes";
import { useRoutes } from "react-router-dom";

export const App = () => {
  const storedValue = localStorage.getItem("token");
  function Greeting() {
    if (storedValue) {
      return <Nav />;
    }
  }
  return (
    <div>
      {Greeting()}
      {useRoutes(routes)}
    </div>
  );
};
