import React, { useCallback, useState } from "react";

import clsx from "clsx";

interface Tab {
  name: string;
  component: JSX.Element;
  current: boolean;
  dataTestId: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

export interface TabsProps {
  tabs: Tab[];
}

export const Tabs = (props: TabsProps) => {
  const [tabs, setTabs] = useState(props.tabs);
  const numberOfTabs = tabs.length;

  const deactivateCurrentlyActiveTab = useCallback(() => {
    const activeTab = tabs.find((tab) => tab.current);
    if (activeTab) {
      activeTab.current = false;
    }
  }, [tabs]);

  const handleTabSwitch = useCallback(
    (tab: Tab) => {
      deactivateCurrentlyActiveTab();
      tab.current = true;
      setTabs([...tabs]);
    },
    [tabs, deactivateCurrentlyActiveTab]
  );

  return (
    <div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                className={clsx(
                  tab.current
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  `w-1/${numberOfTabs} py-2 px-1 inline-flex justify-center border-b-2 font-medium text-sm cursor-pointer`
                )}
                onClick={() => handleTabSwitch(tab)}
                aria-current={tab.current ? "page" : undefined}
                data-testid={tab.dataTestId}
              >
                <tab.icon
                  className={clsx(
                    tab.current
                      ? "text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5"
                  )}
                  aria-hidden="true"
                />
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
        {tabs.find((tab) => tab.current)?.component}
      </div>
    </div>
  );
};
