"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseJson = void 0;
/**
 * Parses a given string into JSON.
 * Gracefully handles invalid JSON by returning `null`.
 */
function parseJson(data) {
    try {
        var json = JSON.parse(data);
        return json;
    }
    catch (_) {
        return null;
    }
}
exports.parseJson = parseJson;
