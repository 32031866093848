import React from "react";
import { CustomerDetails } from "./CustomerDetails";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../../common/Forms/Form";
import { Input } from "../../../../../common/Forms/Input";

export interface CustomerInfoFormProps {
  details: CustomerDetails;
  onEdit: (details: CustomerDetails) => Promise<CustomerDetails>;
}

export const CustomerInfoForm: React.FC<CustomerInfoFormProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="prose" style={{
      // marginLeft:'20vw'
    }}>
      <p className="prose-2xl my-0" data-testid="title-customerName">
        {props.details.firstName} {props.details.lastName}
      </p>
      <p className="prose-sm my-0">
        <span className="text-slate-300">ID:&nbsp;</span>
        <span data-testid="title-customerId" className="text-slate-400">
          {props.details.id}
        </span>
      </p>

      <Form<CustomerDetails>
        onSubmit={(values) => props.onEdit(values)}
        buttonText={t("customerInfo.form.submit")}
        initialValues={{ ...props.details }}
        // validationSchema={schema}
        // testId={testId}
      >
        <h4>{t("customerInfo.form.section.general")}</h4>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <Input
              name="firstName"
              label={t("customerInfo.form.fields.firstName")}
            />
          </div>
          <div className="col-span-1">
            <Input
              name="lastName"
              label={t("customerInfo.form.fields.lastName")}
            />
          </div>
          <div className="col-span-1">
            <Input
              name="socialSecurityNumber"
              label={t("customerInfo.form.fields.nin")}
            />
          </div>
        </div>

        <h4>{t("customerInfo.form.section.invoiceAddress")}</h4>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <Input
              name="invoiceAddress.street"
              label={t("customerInfo.form.fields.street")}
            />
          </div>
          <div className="col-span-1">
            <Input
              name="invoiceAddress.city"
              label={t("customerInfo.form.fields.city")}
            />
          </div>
          <div className="col-span-1">
            <Input
              name="invoiceAddress.postalCode"
              label={t("customerInfo.form.fields.postalCode")}
            />
          </div>
        </div>

        <h4>{t("customerInfo.form.section.contactInfo")}</h4>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <Input
              name="phoneNumber"
              label={t("customerInfo.form.fields.phoneNumber")}
            />
          </div>
          <div className="col-span-1" />
          <div className="col-span-1">
            <Input name="email" label={t("customerInfo.form.fields.email")} />
          </div>
        </div>

        <h4>{t("customerInfo.form.section.updates")}</h4>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <Input
              name="createdAt"
              disabled
              label={t("customerInfo.form.fields.createdAt")}
            />
          </div>
          <div className="col-span-1">
            <Input
              name="updatedAt"
              disabled
              label={t("customerInfo.form.fields.updatedAt")}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};
