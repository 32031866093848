import { getToken } from "../../App/auth";
import { backend } from "../../config/react-app-config";

export const isExistingCustomerFetch = async (phone: string): Promise<any> => {
  const motkraftUser = await getToken();

  return backend.get("/customer/customerExists", {
    headers: {
      "Motkraft-impersonate-user-phone": phone,
      "Motkraft-user": motkraftUser!.authToken,
    },
  });
};
