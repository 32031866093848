import { HomeIcon, UserIcon } from "@heroicons/react/solid";

import { Button } from "@mui/material";
import { CustomerInfo } from "./CustomerInfo/CustomerInfo";
import CustomerMeters from "./CustomerMeters/CustomerMeters";
import React from "react";
import { Tabs } from "../../../common/Tabs/Tabs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { CustomerOverviewPage } from "../CustomerOverviewPage";

export const CustomerPage: React.FC = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const navigateCustomerPage = () => {
    // 👇️ navigate to /
    navigate("/");
  };
  return (
    <div
      style={{
        marginLeft: "1vw",
        marginTop: "1vh",
      }}
    >
      <Button onClick={navigateCustomerPage} type="button" color="primary">
        <HomeIcon className="h-6 w-6 text-blue-500" />
      </Button>
      <Tabs
        tabs={[
          {
            name: t("customerPage.tabs.information"),
            component: <CustomerInfo />,
            current: true,
            dataTestId: "info",
            icon: UserIcon,
          },
          {
            name: t("customerPage.tabs.meters"),
            component: <CustomerMeters />,
            current: false,
            dataTestId: "meters",
            icon: UserIcon,
          },
        ]}
      />
    </div>
  );
};
