import { AxiosError } from "axios";
import { backend } from "../config/react-app-config";
import createTokenQuery from "./tokenQuery";

const localStorageTokenKey = "token";
export const TOKEN_LIFE = 30 * 60 * 1000;
export const REFRESH_TOKEN_LIFE = 10 * 60 * 60 * 1000;

export interface Credentials {
  email: string;
  password: string;
  event?:React.FormEvent<HTMLFormElement>
}

export interface OAuthToken {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  refresh_token_expires_in: number;
  token_type?: string;
  scope?: string;
}

export interface Token {
  authToken: string;
}

const timestampToken = (oauthToken: string): Token => {
  return {
    authToken: oauthToken
  };
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars

const login = async (data: Credentials): Promise<Token> => {
  const params = {
    email: data.email,
    password: data.password,
  };

  return backend
    .post("/login", params, {
      headers: {
        "Content-type": "application/json",
      },
    })
    .then((response) => timestampToken(response.data))
    .catch((error) => {
      throw error;
    });
};

export const logout = async () => {
  auth.logout();
  window.location.replace("/login");
};

const retry = (count: number, error: AxiosError) =>
  count < 3 &&
  !(error.response && error.response.status && error.response.status === 401);

const auth = createTokenQuery<Token, Credentials>({
  queryKey: localStorageTokenKey,
  sendLogin: login,
  retry,
});


auth.init(TOKEN_LIFE - 60 * 1000);

export const useLogin = auth.useLogin;
export const getToken = auth.getToken;
export const authLogout = auth.logout;
export const setTokenValue=auth.setTokenValue;
