import { Card, CardContent, Stack, Typography } from "@mui/material";

import { ImpersonationIcon } from "../../Icons/Impersonation";
import { ListIcon } from "../../Icons/ListIcon";
import Modal from "../Impersonation/Modal";
import { useNavigate } from "react-router";
import { useState } from "react";

type pageDetails = {
  pageName: string;
  pageUrl: string;
  isModel: boolean;
};
export const DashboardScreen = () => {
  const navigate = useNavigate();
  const [first, second]: pageDetails[] = [
    {
      pageName: "Modhi List",
      pageUrl: "/modhiScriptList",
      isModel: false,
    },
    {
      pageName: "ImperSonation",
      pageUrl: "",
      isModel: true,
    },
    {
      pageName: "Customer Overview",
      pageUrl: "/customeroverview",
      isModel: false,
    },
    {
      pageName: "Dashboard",
      pageUrl: "/",
      isModel: false,
    },
  ];

  const navigateToPage =
    (page: pageDetails) => (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      if (!page.isModel) navigate(page.pageUrl);
      else setModalOpen(true);
    };
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Typography
        sx={{
          paddingTop: { md: "25vh", xs: "60px" },
          fontWeight: "bold",
          paddingBottom: { md: "10vh", xs: "3vh" },
        }}
        className={` mt-20 text-center`}
        variant="h3"
      >
        Welcome to Motkraft
      </Typography>
      <Stack
        className=" px-6 mx-2	"
        direction={{ xs: "column", sm: "row" }}
        sx={{ paddingInline: { md: "6.25rem" } }}
        justifyContent="space-between"
        alignItems="center"
        spacing={7}
      >
        <Card
          style={{ boxShadow: "2px 2px 5px 0px #888888" }}
          className="w-full 	h-48"
          onClick={navigateToPage(first)}
        >
          <CardContent className="text-center">
            <div className="flex justify-center pt-3">
              <div
                style={{ backgroundColor: "#61D8AA" }}
                className="p-4 rounded-full"
              >
                <ListIcon fill="white" />
              </div>
            </div>

            <h1 className="font-bold	underline underline-offset-4 pt-3">
              Modhi List
            </h1>
          </CardContent>
        </Card>
        <Card
          style={{ boxShadow: "2px 2px 5px 0px #888888" }}
          className="w-full  h-48	"
          onClick={navigateToPage(second)}
        >
          <CardContent className="text-center">
            <div className="flex justify-center pt-3">
              <div
                style={{ backgroundColor: "#61D8AA" }}
                className="p-4 rounded-full"
              >
                <ImpersonationIcon fill="white" stroke="white" />
              </div>
            </div>
            <h1 className="font-bold	underline underline-offset-4 pt-3">
              Impersonation
            </h1>
          </CardContent>
        </Card>
      </Stack>
      {modalOpen && <Modal setOpenModal={setModalOpen} />}
    </>
  );
};
