import {
  Address,
  CustomerDetails,
} from "./CustomerInfo/CustomerInfoForm/CustomerDetails";
import axios from "axios";
import { backend } from "../../../config/react-app-config";

export const getCustomerInfoDetails = (
  phoneNumber: string
): Promise<CustomerDetails> => {
  return axios
    .get(`/admin/customer/${phoneNumber}`)
    .then((response) => response.data);
};

export interface EditedCustomer {
  readonly firstName?: string;
  readonly lastName?: string;
  readonly nationalIdentityNumber?: string;
  readonly email?: string;
  readonly invoiceAddress?: Address;
}

export const editCustomer = ({
  customerId,
  editedCustomer,
}: {
  customerId: string;
  editedCustomer: EditedCustomer;
}) =>
  backend.put(`/admin/customer/${customerId}`, editedCustomer).then((res) => res.data);

export interface CustomerSubscriptionAddress {
  id: string;
  street: string;
  postalCode: string;
  city: string;
}

export interface CustomerSubscription {
  id: string;
  name?: string;
  meterId?: string;
  meterNumber?: string;
  status: string;
  address?: CustomerSubscriptionAddress;
  product?: string;
  activeFrom?: string;
  activeUntil?: string;
  gridArea?: string;
}

export interface CustomerSubscriptionResponse {
  meters: CustomerSubscription[];
}

export const getCustomerSubscriptions = (
  customerId: string
): Promise<CustomerSubscriptionResponse> => {
  return axios
    .get(`/admin/customer/${customerId}/electricity/meters`)
    .then((res) => res.data);
};
