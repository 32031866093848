import { AppError, getErrorMessage } from "../../App/errorMessageUtils";
import {
  Formik,
  FormikConfig,
  Form as FormikForm,
  FormikHelpers,
  FormikValues,
} from "formik";

import { Button } from "../Buttons/Buttons";
import { Loader } from "../Loader/Loader";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type SubmitHandler<FormData> = (
  values: FormData,
  formikHelpers?: FormikHelpers<FormData>
) => void | Promise<FormData>;

export interface FormProps<FormData> extends FormikConfig<FormData> {
  title?: string;
  onCancel?: () => void;
  buttonText?: string;
  errorMessage?: string;
  onSubmit: SubmitHandler<FormData>;
  intro?: React.ReactNode;
  testId?: string;
}

export interface PublicFormProps<FormData>
  extends Omit<FormProps<FormData>, "initialValues"> {
  initialValues?: FormData;
}

export const Form = <FormData,>({
  title,
  initialValues,
  validationSchema,
  onSubmit,
  children,
  buttonText,
  onCancel,
  errorMessage,
  intro,
  testId,
}: React.PropsWithChildren<FormProps<FormikValues & FormData>>) => {
  const { t } = useTranslation();

  const [errorMessages, setErrorMessages] = useState<string[] | null>();

  const handleSubmit: (
    values: FormikValues & FormData,
    actions?: FormikHelpers<FormikValues & FormData>
  ) => void = async (data, actions) => {
    try {
      //setErrorMessages(null);
      const newFormData = await onSubmit(data);
      actions?.resetForm(newFormData ? { values: newFormData } : undefined);
      // actions.setSubmitting(false);
    } catch (error: any) {
      if (
        error.response &&
        error.response.data.errorCodes &&
        error.response.data.errorCodes.length
      ) {
        const errorMessages = error.response.data.errorCodes.map(
          (code: AppError) => getErrorMessage(code)
        );
        setErrorMessages(errorMessages);
      } else {
        setErrorMessages([t("error.something-has-gone-wrong")]);
      }
    }
  };
  //  ((values: FormikValues & FormData, formikHelpers: FormikHelpers<FormikValues & FormData>) => void | Promise<...>) & ((values: any, actions: any) => void)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <FormikForm
          className="flex flex-col space-y-4"
          data-testid={`form-${testId}`}
          name="testId"
        >
          {title && <h3 className="text-3xl font-bold p-2e">{title}</h3>}
          {intro && <div>{intro}</div>}
          {children}
          <div className="flex ml-auto space-x-4 relative">
            <div className="flex space-x-4 relative">
              {isSubmitting ? (
                <Loader size={"button"} />
              ) : (
                <>
                  {onCancel && (
                    <Button
                      type="button"
                      onClick={onCancel}
                      color="current"
                      disabled={isSubmitting}
                    >
                      {t("form.cancel")}
                    </Button>
                  )}
                  <Button type="submit" disabled={isSubmitting}>
                    {buttonText || t("form.submit")}
                  </Button>
                </>
              )}
            </div>
          </div>
          {(errorMessage || errorMessages) && (
            <div className="relative flex-grow">
              <div
                className="text-red-500 text-s text-right italic"
                role="alert"
              >
                {errorMessage ? (
                  <p data-testid="form-error">{errorMessage}</p>
                ) : (
                  errorMessages?.map((message, index) => (
                    <p key={`error-${index}`} data-testid="form-error">
                      {message}
                    </p>
                  ))
                )}
              </div>
            </div>
          )}
        </FormikForm>
      )}
    </Formik>
  );
};
