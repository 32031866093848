import { Navigate } from "react-router-dom";
import { Role } from "../pages/LoginPage/user-api";
import { queryClient } from "./AppProviders";

interface RequireAuthProps {
  roleRequired: Role;
  children: JSX.Element;
}

export const RequireAuth: React.FC<RequireAuthProps> = ({
  children,
  roleRequired,
}) => {
  // let history = createBrowserHistory();
  // const location = useLocation();
  const existingToken = queryClient.getQueryData("token");

  if (!!existingToken) {
    // user is not authenticated
    return children;
  }
  return <Navigate to="/login" replace={true} />;
};
