import { setupWorker } from "msw";
// @ts-ignore
import importAll from "import-all.macro";
const mocks = importAll.sync("../**/*.mocks.@(js|jsx|ts|tsx)");

const handlers = Object.values(mocks)
  .map((mock) => (mock as any).handlers)
  .flat();

export const worker = setupWorker(...handlers);
