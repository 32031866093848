import { backend } from "../../config/react-app-config";

export enum Role {
  ADMIN = "ADMIN",
}

export interface User {
  id: number;
  email: string;
  roles: Role[];
}

export const getUser = (): Promise<User> => {
  return backend.get("/admin/user").then((response) => response.data);
};
