import { DataGrid, gridClasses } from "@mui/x-data-grid";
import React, { useState } from "react";

import { Button } from "@mui/material";
import { CSVLink } from "react-csv";
import CustomerActions from "./CustomerActions";
import { CustomerBasics } from "../CustomerBasics";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

export interface CustomerListProps {
  readonly customers: CustomerBasics[];
  readonly onEdit: (customerId: number) => void;
}
const headers = [
  { label: "ID", key: "id" },
  { label: "PhoneNumber", key: "phoneNumber" },
  { label: "SocialSecurityNumber", key: "socialSecurityNumber" },
  { label: "FirstName", key: "firstName" },
  { label: "LastName", key: "lastName" },
  { label: "Email", key: "email" },
];

export const CustomerOverviewList: React.FC<CustomerListProps> = (props) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(50);
  const [query, setQuery] = useState("");
  const [data] = useState(props.customers);
  const [arrIds, setarrIds] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleCellClick = (row: any) => {
    if (row.field === "name") {
      props.onEdit(row.id);
    }
  };

  return (
    <>
      <div className=" p-4 mt-16">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div></div>
          <div>
            <input
              type="text"
              placeholder="search..."
              className="search"
              style={{
                borderRadius: "10px",
                borderColor: "grey",
                cursor: "pointer",
              }}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div
        className="px-6"
        style={{
          height: "80vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="font-bold pb-3"
          style={{
            textAlign: "center",
            fontSize: "1.25rem",
          }}
        >
          <Title />
        </div>
        <div style={{ flex: "auto", borderSpacing: "0px 4px" }}>
          <DataGrid
            onCellClick={handleCellClick}
            disableColumnMenu
            rowHeight={65}
            columns={[
              {
                field: "actions",
                type: "actions",
                headerName: "Action",
                width: 200,
                renderCell: (params) => <CustomerActions {...{ params }} />,
              },
              {
                field: "id",
                type: "string",
                headerName: t("customerList.header.id"),
                flex: 1,
              },
              {
                field: "name",
                type: "string",
                headerName: t("customerList.header.name"),
                flex: 1,
              },
              {
                field: "phoneNumber",
                type: "string",
                headerName: t("customerList.header.phoneNumber"),
                flex: 1,
              },
              {
                field: "email",
                type: "string",
                headerName: t("customerList.header.email"),
                flex: 1,
              },
              {
                field: "socialSecurityNumber",
                type: "string",
                headerName: t("customerList.header.socialSecurityNumber"),
                flex: 1,
                sortable: false,
                headerClassName: "lastcolumnSeparator",
              },
            ]}
            rows={data
              .filter(
                (user) =>
                  (user.firstName || "")
                    .toLowerCase()
                    .includes(query.toLowerCase()) ||
                  (user.lastName || "")
                    .toLowerCase()
                    .includes(query.toLowerCase()) ||
                  (user.phoneNumber || "").includes(query) ||
                  (user.email || "")
                    .toLowerCase()
                    .includes(query.toLowerCase()) ||
                  (user.id || "").toString().toLowerCase().includes(query)
              )
              .map((cb, index) => ({
                ...cb,
                name: `${cb.firstName} ${cb.lastName}`,
                socialSecurityNumber:
                  cb.socialSecurityNumber &&
                  `${cb.socialSecurityNumber.substring(0, 4)}*******`,
              }))}
            disableColumnSelector={true}
            disableColumnFilter={true}
            checkboxSelection
            rowsPerPageOptions={[10, 20, 50, 100]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            sortingOrder={["asc", "desc"]}
            onSelectionModelChange={(selectedData: any) => {
              if (selectedData.length > 0) {
                const selectedData1: any = data.filter((dataValue: any) =>
                  selectedData.includes(dataValue.id)
                );
                setarrIds(selectedData1);
                setBtnDisabled(false);
              } else {
                setBtnDisabled(true);
              }
            }}
            sx={{
              [`& .${gridClasses.row}`]: {
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? grey[200] : grey[900],
              },
            }}
          />
          <div className="mt-5 pb-3 flex justify-between	">
            <CSVLink data={data} headers={headers} filename="customerList.csv">
              <Button
                variant="contained"
                style={{
                  // marginLeft: "3vw",
                  borderRadius: "10px",
                  backgroundColor: "#61D8AA",
                }}
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>Export CSV</span>
              </Button>
            </CSVLink>
            <CSVLink
              data={arrIds}
              headers={headers}
              filename="selectedRecords.csv"
            >
              <Button
                variant="contained"
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#61D8AA",
                }}
                disabled={btnDisabled}
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>Download Selected Records</span>
              </Button>
            </CSVLink>
          </div>
        </div>
      </div>
    </>
  );
};

const Title = () => {
  const { t } = useTranslation();

  return <h2>{t("customerOverview.title")}</h2>;
};
