import { Loader } from "../Loader/Loader";

export interface ButtonProps {
  Element?: React.ElementType;
  color?: string;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
}

export const Button = <ElementProps,>({
  Element = "button",
  children,
  color = "brand",
  disabled,
  className,
  loading,
  ...rest
}: ButtonProps & ElementProps) => {
  return (
    <Element
      className={`relative inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2
      ${
        disabled
          ? "bg-gray-500 pointer-events-none cursor-not-allowed"
          : `bg-${color}-600 hover:bg-${color}-700 focus:ring-${color}-500`
      }
      ${className ? className : ""}
      `}
      disabled={disabled}
      {...rest}
    >
      <div className={loading ? "invisible" : ""}>{children}</div>
      {loading && (
        <div className="absolute w-full -ml-4 flex justify-center">
          <Loader size="button" />
        </div>
      )}
    </Element>
  );
};
