import { reactAppConfig } from "../../config/react-app-config";
import { useLocation } from "react-router-dom";

const ImpersonationPage = () => {
  const location: any = useLocation();

  return (
    <>
      <div className="grid place-content-center mt-20 mb-16">
        {location && location.state && location.state.value && (
          <iframe
            id="myFrame"
            title="myFrame"
            src={`${reactAppConfig.mobileAppUrl}splash/?headervalue=${location.state.value}`}
            width="380"
            height="680"
          ></iframe>
        )}
      </div>
    </>
  );
};

export default ImpersonationPage;
