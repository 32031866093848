import { DataGrid, GridCellEditCommitParams } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../common/Buttons/Buttons";
import GridFactory from "../../../../../utils/GridFactory";
const { constructColumn } = new GridFactory("customerMeters.list");

export interface CustomerMeterListItem {
  rowId: number;
  id?: string;
  name?: string;
  meterId?: string;
  meterNumber?: string;
  status?: string;
  addressId?: string;
  city?: string;
  street?: string;
  postalCode?: string;
  activeFrom?: string;
  activeUntil?: string;
  gridArea?: string;
}

interface CustomerMeterListProps {
  meters: CustomerMeterListItem[];
  onAdd: () => void;
  onEdit: (meter: CustomerMeterListItem) => void;
}

const columns = [
  constructColumn("street"),
  constructColumn("postalCode"),
  constructColumn("city"),
  constructColumn("name"),
  constructColumn("meterId"),
  constructColumn("meterNumber"),
  constructColumn("status", undefined, undefined, false),
  constructColumn("activeFrom"),
  constructColumn("activeUntil"),
  constructColumn("gridArea"),
];

const CustomerMetersList: React.FC<CustomerMeterListProps> = ({
  meters,
  onAdd,
  onEdit,
}) => {
  const { t } = useTranslation();

  const handleEdit = (params: GridCellEditCommitParams): void => {
    const meter = meters.find((x) => x.rowId === params.id);
    if (!meter) return;

    Object.assign(meter, { [params.field]: params.value });
    return onEdit(meter);
  };
  const[query ,setQuery] =useState("");
  return (
    <><input type="text" placeholder="serach..." className="search" style={{
      height: "5vh",
      width: "20%",
      display: "flex",
      marginTop: "3vh",
      marginLeft: "75vw",
      borderRadius: "10px",
      borderColor: "grey",
      cursor: "pointer"
    }} onChange={(e) => setQuery(e.target.value)} /><div
      className="px-6 py-6"
      style={{
        height: "78vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        // marginLeft:'5vw'
      }}
    >
        <div style={{ flex: "auto" }}>
          <DataGrid
           disableColumnMenu
            columns={columns}
            rows={meters.filter(meter=>(meter.street || '').toLowerCase().includes(query.toLowerCase()) || (meter.city || '').toLowerCase().includes(query.toLowerCase()) ||
            (meter.name || '').toLowerCase().includes(query.toLowerCase()) || (meter.meterId || '').includes(query) ||
            (meter.meterNumber || '').includes(query) || (meter.postalCode || '').includes(query) ||
            (meter.status || '').toLowerCase().includes(query.toLowerCase()) || (meter.activeFrom || '').toLowerCase().includes(query.toLowerCase()) ||
            (meter.activeUntil || '').toLowerCase().includes(query.toLowerCase()) || (meter.gridArea || '').toLowerCase().includes(query.toLowerCase())


            )}
            getRowId={(row) => row.rowId}
            hideFooterPagination={true}
            hideFooter={true}
            disableColumnSelector={true}
            disableColumnFilter={true}
            onCellEditCommit={handleEdit} /><br></br>
          <Button onClick={onAdd}>{t("customerMeters.buttons.add")}</Button>
        </div>
      </div></>
  );
};

export default CustomerMetersList;
